export const ADVISEE_SELECTED = "ADVISEE_SELECTED"

export const GET_ADVISORS_BEGIN = "GET_ADVISORS_BEGIN"
export const GET_ADVISORS_ERROR = "GET_ADVISORS_ERROR"
export const GET_ADVISORS_SUCCESS = "GET_ADVISORS_SUCCESS"

export const GET_ADVISEES_BEGIN = "GET_ADVISEES_BEGIN"
export const GET_ADVISEES_ERROR = "GET_ADVISEES_ERROR"
export const GET_ADVISEES_SUCCESS = "GET_ADVISEES_SUCCESS"

export const INVITE_ADVISOR_BEGIN = "INVITE_ADVISOR_BEGIN"
export const INVITE_ADVISOR_ERROR = "INVITE_ADVISOR_ERROR"
export const INVITE_ADVISOR_SUCCESS = "INVITE_ADVISOR_SUCCESS"

export const ACCEPT_ADVISOR_INVITATION_BEGIN = "ACCEPT_ADVISOR_INVITATION_BEGIN"
export const ACCEPT_ADVISOR_INVITATION_ERROR = "ACCEPT_ADVISOR_INVITATION_ERROR"
export const ACCEPT_ADVISOR_INVITATION_SUCCESS = "ACCEPT_ADVISOR_INVITATION_SUCCESS"

export const PATCH_ADVISOR_INVITATION_BEGIN = "PATCH_ADVISOR_INVITATION_BEGIN"
export const PATCH_ADVISOR_INVITATION_ERROR = "PATCH_ADVISOR_INVITATION_ERROR"
export const PATCH_ADVISOR_INVITATION_SUCCESS = "PATCH_ADVISOR_INVITATION_SUCCESS"

export const DELETE_ADVISOR_BEGIN = "DELETE_ADVISOR_BEGIN"
export const DELETE_ADVISOR_ERROR = "DELETE_ADVISOR_ERROR"
export const DELETE_ADVISOR_SUCCESS = "DELETE_ADVISOR_SUCCESS"