import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AccountCircle from '@material-ui/icons/AccountCircle';
import {LOGOUT_URL} from '../../constants/urls';
import localization from 'moment/locale/fr';

import AppLogo from '../../static/img/large-logo-MGC.png';
import store from '../../store';
import { deleteCookie } from '../../helpers/cookies';

import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const mapStateToProps = state => {
    return {
        user: state.user.activeUserProfile,
        selectedAdvisee: state.advisors.selectedAdvisee,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        

    };
};

const styles = theme => ({
    root: {
        flexGrow: 1,
        fontFamily: theme.typography.fontFamily
    },
    AppBar: {
        background: "#fff",
        color: "#333",
        boxShadow: "0px 1px 10px rgba(0,0,0,0.2);",
        zIndex: 10,
        position: "relative"
    },
    advisorAppBar: {
        background: theme.palette.primary.dark,
        color: '#fff',
        '& a, button': {
            color: '#fff',
        },
    },
    titleButton: {
        textTransform: "none",
        fontSize: "1.2rem",
        fontWeight: "400"
    },
    brand: {
        flexGrow: 1,
        display: "flex",
        alignItems: "center"
    },
    BrandImage: {
        maxHeight: 40,
        marginRight: 10
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    fixedNavBar: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1
    }
    
});

class MainNav extends React.Component {
    constructor(props) {
        super(props)
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.state = {
            accMenuAnchorEl: null
        }
        this.handleAccMenuClick = this.handleAccMenuClick.bind(this)
        this.handleAccMenuClose = this.handleAccMenuClose.bind(this)
        this.changeLanguage = this.changeLanguage.bind(this)
        this.setMomentLocale = this.setMomentLocale.bind(this)
        this.setMomentLocale();
    }
    handleLogoutClick() {
        deleteCookie('selectedAdvisee');
        window.location = `${LOGOUT_URL}?next=${window.location.href}`;
    }
    handleViewProfileClick() {
        deleteCookie('selectedAdvisee');
        window.location = "/collaborate";
    }

    handleAccMenuClick(event) {
        this.setState({accMenuAnchorEl: event.currentTarget});
    }

    handleAccMenuClose() {
        this.setState({accMenuAnchorEl: null});
    }

    changeLanguage() {
        const {t, i18n} = this.props;
        let currentLang = i18n.language;
        let newLang;
        if (currentLang.startsWith("en")) {newLang = "fr"}
        else {newLang = "en"}
        if (newLang == "fr") {
            moment.locale('fr', localization);

            // moment.locale('fr', {
            //     months : 'Janvier_Février_Mars_Avril_Mai_Juin_Juillet_Août_Septembre_Octobre_Novembre_Décembre'.split('_'),
            //     monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
            //     monthsParseExact : true,
            //     weekdays : 'Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi'.split('_'),
            //     weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
            //     weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
            //     weekdaysParseExact : true,
            //     longDateFormat : {
            //         LT : 'HH:mm',
            //         LTS : 'HH:mm:ss',
            //         L : 'DD/MM/YYYY',
            //         LL : 'D MMMM YYYY',
            //         LLL : 'D MMMM YYYY HH:mm',
            //         LLLL : 'dddd D MMMM YYYY HH:mm'
            //     },
            //     calendar : {
            //         sameDay : '[Aujourd’hui à] LT',
            //         nextDay : '[Demain à] LT',
            //         nextWeek : 'dddd [à] LT',
            //         lastDay : '[Hier à] LT',
            //         lastWeek : 'dddd [dernier à] LT',
            //         sameElse : 'L'
            //     },
            //     relativeTime : {
            //         future : 'dans %s',
            //         past : 'il y a %s',
            //         s : 'quelques secondes',
            //         m : 'une minute',
            //         mm : '%d minutes',
            //         h : 'une heure',
            //         hh : '%d heures',
            //         d : 'un jour',
            //         dd : '%d jours',
            //         M : 'un mois',
            //         MM : '%d mois',
            //         y : 'un an',
            //         yy : '%d ans'
            //     },
            //     dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
            //     ordinal : function (number) {
            //         return number + (number === 1 ? 'er' : 'e');
            //     },
            //     meridiemParse : /PD|MD/,
            //     isPM : function (input) {
            //         return input.charAt(0) === 'M';
            //     },
            //     // In case the meridiem units are not separated around 12, then implement
            //     // this function (look at locale/id.js for an example).
            //     // meridiemHour : function (hour, meridiem) {
            //     //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
            //     // },
            //     meridiem : function (hours, minutes, isLower) {
            //         return hours < 12 ? 'pd' : 'md';
            //     },
            //     week : {
            //         dow : 1, // Monday is the first day of the week.
            //         doy : 4  // Used to determine first week of the year.
            //     }
            // });
        } else {
            moment.locale('en');
        }
        i18n.changeLanguage(newLang, (err, t) => {
            if (err) return console.log('something went wrong loading', err);
        });
    }

    setMomentLocale(){
                const {t, i18n} = this.props;

        if (i18n.language == "fr") {
            moment.locale('fr', localization);
        } else {
            moment.locale('en');
        }
    }

    render() {
        const { classes, user, selectedAdvisee, t, i18n } = this.props;
        
        let optionClasses = this.props.fixed ? classes.fixedNavBar : "",
            advisorDashboard = Boolean(selectedAdvisee.advisee),
            dataOwner = advisorDashboard ? selectedAdvisee.advisee.username : user.data.first_name,
            displayGarage = !this.props.selectedAdvisee || !this.props.selectedAdvisee.advisee || this.props.selectedAdvisee.permissions.view_car_profile ,
            displayAddCars = !this.props.selectedAdvisee || !this.props.selectedAdvisee.advisee || this.props.selectedAdvisee.permissions.add_car;

        return (
            <div className={[classes.root, optionClasses].join(" ")}>
                <AppBar className={ advisorDashboard ? `${classes.AppBar} ${classes.advisorAppBar}` : `${classes.AppBar}`} >
                    <Toolbar className={classes.Toolbar}>
                        <div className={classes.brand}>
                            <Button size="small" className={classes.titleButton} component={Link} to="/">
                                <img src={AppLogo} className={classes.BrandImage}/>
                                <div>
                                    MyGreenCar {advisorDashboard ? <strong>{t('nav.advisor')}</strong> : ''}{t('nav.dashboard')}
                                </div>
                            </Button>
                        </div>


                        { advisorDashboard ?
                            <Button size="medium" onClick={this.handleViewProfileClick}>{t('nav.backToAccount')}</Button>
                        :
                            null
                        }

                        <Button size="small" component={Link} to="/">{t('nav.home')}</Button>
                        <Button size="small" component={Link} to="/activity">{t('nav.activity')}</Button>
                        <Button size="small" component={Link} to="/electric">{t('nav.evInsights')}</Button>

                        { displayGarage ?
                            <Button size="small" component={Link} to="/garage">{t('nav.garage')}</Button>
                        :
                            null
                        }

                        { displayGarage ?
                            <Button size="small" component={Link} to="/compare">{t('nav.compare')}</Button>
                        :
                            null
                        }

                        { displayAddCars ?
                            <Button size="small" component={Link} to="/garage/add">{t('nav.addCars')}</Button>
                        :
                            null
                        }

                        <Button size="small" component={Link} to="/collaborate">{t('nav.collaborate')}</Button>

                        <Button size="small" onClick={this.handleAccMenuClick}>
                            <AccountCircle className={classes.leftIcon}/>
                            {dataOwner}
                        </Button>

                        <Menu
                            id="account-menu"
                            anchorEl={this.state.accMenuAnchorEl}
                            keepMounted
                            open={Boolean(this.state.accMenuAnchorEl)}
                            onClose={this.handleAccMenuClose}
                        >
                            <MenuItem component={Link} to="/settings">{t('nav.settings')}</MenuItem>
                            <MenuItem onClick={this.changeLanguage}>{t('nav.changeLanguage')}</MenuItem>
                            <MenuItem onClick={this.handleLogoutClick}>{t('nav.logout')}</MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MainNav)));