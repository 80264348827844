import React, { Component } from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';

import { Typography } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import MainNav from './MainNav';

const mapStateToProps = state => {
    return {
        
    };
};

const mapDispatchToProps = dispatch => {
    return {
        
    };
};

const styles = theme => ({
    loader: {
        fontFamily: theme.typography.fontFamily,
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
});

class FullPageLoader extends React.Component {
    
    render() {
        const { classes } = this.props;

        return (
            <div>
                <div className={classes.loader}>
                    <Fade in={true} timeout={500}>
                        <div style={{ textAlign: "center" }}>
                            <CircularProgress className={classes.progress} />
                            <div>
                                {this.props.message}
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FullPageLoader))