// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react'
import { connect } from "react-redux";
import { Redirect, Route, withRouter } from 'react-router-dom'
import { getActiveUserProfile } from '../../actions/user_actions'
import {LOGIN_URL} from '../../constants/urls'
import FullPageLoader from '../_include/FullPageLoader'
import queryString from 'query-string';

const mapStateToProps = state => {
    return {
        user: state.user.activeUserProfile
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getActiveUserProfile: () => dispatch(getActiveUserProfile())
    };
};

// const PrivateRoute = ({ component: Component, user, ...rest }) => (
//     <Route {...rest} render={(props) => (
//         (user.data && user.data.hasOwnProperty("username")) === true
//             ? <Component {...props} />
//             : <div>No auth</div>
//     )} />
// )

function removeURLParameter(url, parameter) {
    //prefer to use l.search if you have a location/link object
    var urlparts = url.split('?');   
    if (urlparts.length >= 2) {

        var prefix = encodeURIComponent(parameter) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (var i = pars.length; i-- > 0;) {    
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {  
                pars.splice(i, 1);
            }
        }

        return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
}

class PrivateRoute extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { component: Component, ...rest } = this.props;
        const authenticated = (this.props.user.data && this.props.user.data.hasOwnProperty("username"));
        
        //{/*<Redirect to={{ pathname: '/login', state: { from: props.location } }} />*/}
        return (
            <Route
                {...rest}
                render={
                    props => {
                        if (this.props.user.loading ) {
                            return (
                                <FullPageLoader message="Authenticating..."/>
                            )
                        } else if (this.props.user.error) {
                            setTimeout(() => {
                                window.location = `${LOGIN_URL}?next=${window.location.href}`;
                            }, 1000);
                            return (
                                <FullPageLoader message="ERROR! Redirecting to login..."/>
                            )
                        } else {
                            console.log("User stopped loading.", this.props.user)
                            if (authenticated) {
                                return (
                                    <Component {...props} />
                                )
                            } else {
                                let email = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true }).email;
                                let href = window.location.href;
                                if (email) {
                                    email = `email=${email}&`;
                                    href = removeURLParameter(href, 'email');
                                }
                                else {
                                    email = '';
                                }
                                
                                window.location = `${LOGIN_URL}?${email}next=${href}`;
                                return (
                                    <FullPageLoader message="Redirecting to login..."/>
                                )
                            }
                        }
                        
                    }
                }
            />
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateRoute))