export const GET_TRIPS_BEGIN = "GET_TRIPS_BEGIN"
export const GET_TRIPS_ERROR = "GET_TRIPS_ERROR"
export const GET_TRIPS_SUCCESS = "GET_TRIPS_SUCCESS"

export const GET_SINGLE_TRIP_BEGIN = "GET_SINGLE_TRIP_BEGIN"
export const GET_SINGLE_TRIP_ERROR = "GET_SINGLE_TRIP_ERROR"
export const GET_SINGLE_TRIP_SUCCESS = "GET_SINGLE_TRIP_SUCCESS"

export const DELETE_TRIP_BEGIN = "DELETE_TRIP_BEGIN"
export const DELETE_TRIP_ERROR = "DELETE_TRIP_ERROR"
export const DELETE_TRIP_SUCCESS = "DELETE_TRIP_SUCCESS"
