import {
    ADD_ACCOUNT_BEGIN,
    ADD_ACCOUNT_ERROR,
    ADD_ACCOUNT_SUCCESS,
} from "../constants/action-types-onboarding";

const initialState = {
    account: {
        data: null,
        loading: false,
        error: null
    },
};

const onboarding = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ACCOUNT_BEGIN:
            return {...state, account: {data: null, loading: true, error: null}}
        case ADD_ACCOUNT_SUCCESS:
            return {...state, account: {data: action.payload[0], loading: false, error: null}}
        case ADD_ACCOUNT_ERROR:
            return {...state, account: {data: null, loading: false, error: action.payload}}
        default:
            return state
    }
}

export default onboarding;
