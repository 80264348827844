import {
    ADVISEE_SELECTED,
    GET_ADVISORS_BEGIN,
    GET_ADVISORS_ERROR,
    GET_ADVISORS_SUCCESS,
    GET_ADVISEES_BEGIN,
    GET_ADVISEES_ERROR,
    GET_ADVISEES_SUCCESS,
    INVITE_ADVISOR_BEGIN,
    INVITE_ADVISOR_ERROR,
    INVITE_ADVISOR_SUCCESS,
    ACCEPT_ADVISOR_INVITATION_BEGIN,
    ACCEPT_ADVISOR_INVITATION_ERROR,
    ACCEPT_ADVISOR_INVITATION_SUCCESS,
    PATCH_ADVISOR_INVITATION_BEGIN,
    PATCH_ADVISOR_INVITATION_ERROR,
    PATCH_ADVISOR_INVITATION_SUCCESS,
    DELETE_ADVISOR_BEGIN,
    DELETE_ADVISOR_ERROR,
    DELETE_ADVISOR_SUCCESS,
} from "../constants/action-types-advisor";
import { getCookie, setCookie } from "../helpers/cookies";

const initialState = {
    advisors: {
        data: null,
        loading: false,
        error: null
    },
    advisees: {
        data: null,
        loading: false,
        error: null
    },
    selectedAdvisee: (getCookie('selectedAdvisee') ? 
        JSON.parse(getCookie('selectedAdvisee'))
    :
        {
            advisee: null,
            permissions: null,
        }
    ),
};

const advisors = (state = initialState, action) => {
    switch (action.type) {
        case ADVISEE_SELECTED:
            let selectedAdvisee = {advisee: action.payload.advisee, permissions: action.payload.permissions},
                days_to_expiry = (new Date(action.payload.expiry_date).getTime() - new Date().getTime()) / 1000 / 3600 / 24;
            setCookie('selectedAdvisee', JSON.stringify(selectedAdvisee), days_to_expiry);
            return {...state, selectedAdvisee: selectedAdvisee};

        case GET_ADVISORS_BEGIN:
            return {...state, advisors: {data: null, loading: true, error: null}};
        case GET_ADVISORS_ERROR:
            return {...state, advisors: {data: null, loading: false, error: action.payload}};
        case GET_ADVISORS_SUCCESS:
            return {...state, advisors: {data: action.payload, loading: false, error: null}};

        case INVITE_ADVISOR_BEGIN:
            return {...state, advisors: {...state.advisors, loading: true, error: null}};
        case INVITE_ADVISOR_ERROR:
            alert("ERROR: Couldn't send invitation email to " + action.payload.advisor_email);
            return {...state, advisors: {...state.advisors, loading: false, error: action.payload}};
        case INVITE_ADVISOR_SUCCESS:
            alert("An invitation email has been sent to " + action.payload.advisor_email);
            return {...state, advisors: {loading: false, error: null, data: [...state.advisors.data, action.payload.result]}};

        case GET_ADVISEES_BEGIN:
            return {...state, advisees: {data: null, loading: true, error: null}};
        case GET_ADVISEES_ERROR:
            return {...state, advisees: {data: null, loading: false, error: action.payload}};
        case GET_ADVISEES_SUCCESS:
            return {...state, advisees: {data: action.payload, loading: false, error: null}};

        case ACCEPT_ADVISOR_INVITATION_BEGIN:
            return {...state, advisees: {loading: true, error: null, data: null}};
    
        case ACCEPT_ADVISOR_INVITATION_ERROR:
            return {...state, advisees: {loading: false, error: action.payload, data: state.advisees.data}};

        case ACCEPT_ADVISOR_INVITATION_SUCCESS:
            selectedAdvisee = {advisee: action.payload.advisee, permissions: action.payload.permissions};
            days_to_expiry = (new Date(action.payload.expiry_date).getTime() - new Date().getTime()) / 1000 / 3600 / 24;
            setCookie('selectedAdvisee', JSON.stringify(selectedAdvisee), days_to_expiry);
            let advisees = state.advisees;
            if (advisees.data) { // some advisees were already loaded
                advisees = {loading: false, error: null, data: [...state.advisees.data, action.payload]};
            }
            else { // no advisees loaded
                advisees = {loading: false, error: null, data: [action.payload]};
            }
            return {...state, selectedAdvisee: selectedAdvisee, advisees: advisees};
            
        case PATCH_ADVISOR_INVITATION_BEGIN:
            return {...state, advisors: {...state.advisors, loading: true, error: null}};
        case PATCH_ADVISOR_INVITATION_ERROR:
            alert("ERROR: Couldn't update the advisor permissions");
            return {...state, advisors: {...state.advisors, loading: false, error: action.payload}};
        case PATCH_ADVISOR_INVITATION_SUCCESS:
            alert("A notification has been sent to the advisor");
            return {...state, advisors: {data: [...state.advisors.data.filter(a => a.id != action.payload.id), action.payload], loading: false, error: null}};

        case DELETE_ADVISOR_BEGIN:
            return {...state, advisors: {...state.advisors, loading: true, error: null}};
        case DELETE_ADVISOR_ERROR:
                alert("ERROR: Couldn't revoke the advisor permissions");
            return {...state, advisors: {...state.advisors, loading: false, error: action.payload}};
        case DELETE_ADVISOR_SUCCESS:
            return {...state, advisors: {data: state.advisors.data.filter(a => a.id != action.invitation_id), loading: false, error: null}};
        
        default:
            return state;
    }
}

export default advisors;