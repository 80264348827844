import React, { Component } from 'react';
import { connect } from "react-redux";
import { Route, BrowserRouter as Router } from 'react-router-dom';
import Loadable from 'react-loadable';
import FullPageLoader from './_include/FullPageLoader';

import PrivateRoute from './Auth/PrivateRoute';
import {getActiveUserProfile} from '../actions/user_actions';

const mapStateToProps = state => {
    return {
        user: state.user.activeUserProfile
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getActiveUserProfile: () => dispatch(getActiveUserProfile())
    };
};

// Loading spinner for asynchronous page content load.
// (We reduce initial JS bundle size)
function Loading({ pastDelay, error }) {
    if (!pastDelay) {
        return null
    } else if (error) {
        return (
            <div>
                <div>
                    ERROR
                </div>
            </div>
        )
    } else {
        return <FullPageLoader />
    }
}


// App and loadable Pages
const WelcomePage = Loadable({
    loader: () => import('./Welcome/WelcomePage'),
    loading: Loading
});

const HomePage = Loadable({
    loader: () => import('./Home/HomePage'),
    loading: Loading
});

const ActivityPage = Loadable({
    loader: () => import('./Activity/ActivityPage'),
    loading: Loading
});

const EVInsightsPage = Loadable({
    loader: () => import('./EVInsights/EVInsightsPage'),
    loading: Loading
});

const SettingsPage = Loadable({
    loader: () => import('./Profile/SettingsPage'),
    loading: Loading
});

const GaragePage = Loadable({
    loader: () => import('./Garage/Garage/GaragePage'),
    loading: Loading
});

const ComparePage = Loadable({
    loader: () => import('./Compare/ComparePage'),
    loading: Loading
});

const SingleVehiclePage = Loadable({
    loader: () => import('./Garage/SingleVehicle/SingleVehiclePage'),
    loading: Loading
});

const AddCarPage = Loadable({
    loader: () => import('./Garage/AddCarPage/AddCarPage'),
    loading: Loading
});

const AdvisorsPage = Loadable({
    loader: () => import('./Advisors/InvitationPage/AdvisorsPage'),
    loading: Loading
});

const AcceptAdvisorInvitationPage = Loadable({
    loader: () => import('./Advisors/AcceptInvitationPage'),
    loading: Loading
});


class Pages extends React.Component {
    componentDidMount() {
        this.props.getActiveUserProfile()
        ComparePage.preload()
        GaragePage.preload()
    }

    render() {
        const { user } = this.props;

        return (
            <React.Fragment>
                <Route exact path="/welcome" component={WelcomePage} />
                <PrivateRoute exact path="/" component={HomePage} />
                <PrivateRoute exact path="/activity" component={ActivityPage} />
                <PrivateRoute exact path="/electric" component={EVInsightsPage} />
                <PrivateRoute exact path="/settings" component={SettingsPage} />
                <PrivateRoute exact path="/compare" component={ComparePage} />
                <PrivateRoute exact path="/garage" component={GaragePage} />
                <PrivateRoute exact path="/garage/add" component={AddCarPage} />
                <PrivateRoute path="/garage/single_vehicle/:car_profile_id" component={SingleVehiclePage} />
                <PrivateRoute exact path="/collaborate" component={AdvisorsPage} />
                <PrivateRoute path="/collaborate/invitation/:invitation_id/accept/" component={AcceptAdvisorInvitationPage} />

            </React.Fragment>
        )
        
        
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(Pages);