import {
    GET_LOCATIONS_BEGIN,
    GET_LOCATIONS_ERROR,
    GET_LOCATIONS_SUCCESS,
    SELECT_LOCATION,
    TOGGLE_LOCATION_LIST,
    EDIT_LOCATION_BEGIN,
    EDIT_LOCATION_ERROR,
    EDIT_LOCATION_SUCCESS
} from "../constants/action-types-location.js";
import { ADVISEE_SELECTED } from "../constants/action-types-advisor.js";

const initialState = {
    locations: {
        data: null,
        loading: false,
        error: null
    },
    selectedLocation:{
      location: {},
    //   activeMarker: marker,
      showingInfoWindow: false
    },
    locationListToggle:false
};

const locations = (state = initialState, action) => {
    switch (action.type) {
        case ADVISEE_SELECTED:
            return {...state, locations: {data: null, loading: false, error: null}}

        case GET_LOCATIONS_BEGIN:
            return {...state, locations: {data: null, loading: true, error: null}}

        case GET_LOCATIONS_ERROR:
            return {...state, locations: {data: null, loading: false, error: action.payload}}

        case GET_LOCATIONS_SUCCESS:
            return {...state, locations: {data: action.payload, loading: false, error: null}}

        // case GET_LOCATIONS_BEGIN:
        //     return {...state, locations: {data: null, loading: true, error: null}}

        // case GET_LOCATIONS_ERROR:
        //     return {...state, locations: {data: null, loading: false, error: action.payload}}

        case EDIT_LOCATION_SUCCESS:
        console.log(action.payload)
            return {...state, locations: {data: state.locations.data.map((loc) => loc.id == action.payload.id?action.payload:loc), loading: false, error: null}};

        case SELECT_LOCATION:
            return {...state, selectedLocation: action.payload}

        case TOGGLE_LOCATION_LIST:
            return {...state, locationListToggle: !state.locationListToggle}

        case EDIT_LOCATION_SUCCESS:

        default:
            return state;
    }
}

export default locations;