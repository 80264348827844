/**
 * Set a cookie value in the user's browser, creating a new one if not existing.
 * @param {string} cname Name of the cookie
 * @param {string|number} cvalue Value for the cookie
 * @param {number} exdays Number of days before the cookie expires
 */
export function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

/**
 * Get the value associated to a cookie
 * @param {string} cname Name of the cookie
 * @returns {string} The value of the cookie
 */
export function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

/**
 * "Delete" a cookie by making it expired 
 * @param {string} cname Name of the cookie
 */
export function deleteCookie(cname) {
    document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}