import {
    GET_ACTIVE_USER_PROFILE_BEGIN,
    GET_ACTIVE_USER_PROFILE_ERROR,
    GET_ACTIVE_USER_PROFILE_SUCCESS,

    POST_USERPROFILE_BEGIN,
    POST_USERPROFILE_ERROR,
    POST_USERPROFILE_SUCCESS,

    CHANGE_USERPROFILE_SETTING

} from "../constants/action-types-user";

const initialState = {
    activeUserProfile: {
        data: null,
        loading: true, // Initially we start loading directly
        error: null
    },
    settingsData: {} // a partial copy of userprofile with flattened data for sending changes back to server
};

const user = (state = initialState, action) => {
    switch (action.type) {
        
        case GET_ACTIVE_USER_PROFILE_BEGIN:
            return {...state, activeUserProfile: {data: null, loading: true, error: null}}

        case GET_ACTIVE_USER_PROFILE_ERROR:
            return {...state, activeUserProfile: {data: null, loading: false, error: action.payload}}

        case GET_ACTIVE_USER_PROFILE_SUCCESS:
            let userdata = {}
            // Check if we have data. Response is a list
            if (Array.isArray(action.payload) && action.payload.length >0) {
                userdata = action.payload[0]
            }
            // Set global CSRF token
            window.csrftoken = userdata.csrftoken;
            console.log("Set CSRFtoken to ", window.csrftoken)
            return {...state, 
                activeUserProfile: {data: userdata, loading: false, error: null},
                settingsData: {
                    ...state.settingsData,
                    sim_temperature: userdata.userprofile.sim_temperature,
                    use_sim_temperature: userdata.userprofile.use_sim_temperature,
                    id: userdata.userprofile.id,
                    annual_distance: userdata.userprofile.annual_distance.value,
                    gas_price: userdata.userprofile.gas_price.value,
                    diesel_price: userdata.userprofile.diesel_price.value,
                    use_SI: userdata.userprofile.use_SI
                }
            }

        case POST_USERPROFILE_BEGIN:
            return {...state, activeUserProfile: {...state.activeUserProfile, loading: true}}

        case POST_USERPROFILE_ERROR:
            return {...state, activeUserProfile: {...state.activeUserProfile, loading: false}}

        case POST_USERPROFILE_SUCCESS:
            return {...state, activeUserProfile: {
                ...state.activeUserProfile, data: {...state.activeUserProfile.data, userprofile: action.payload}, loading: false, error: null},
                settingsData: {
                    ...state.settingsData,
                    sim_temperature: action.payload.sim_temperature,
                    id: action.payload.id,
                    annual_distance: action.payload.annual_distance.value,
                    gas_price: action.payload.gas_price.value,
                    diesel_price: action.payload.diesel_price.value,
                    use_SI: action.payload.use_SI
                }
            }
        
        case CHANGE_USERPROFILE_SETTING:
            return {...state, settingsData: {...state.settingsData, [action.key]: action.value}}
        default:
            return state;
    }
}

export default user;