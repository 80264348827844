import {
    GET_ACTIVE_USER_PROFILE_BEGIN,
    GET_ACTIVE_USER_PROFILE_ERROR,
    GET_ACTIVE_USER_PROFILE_SUCCESS,

    POST_USERPROFILE_BEGIN,
    POST_USERPROFILE_ERROR,
    POST_USERPROFILE_SUCCESS,

    CHANGE_USERPROFILE_SETTING

} from "../constants/action-types-user";
import { API_URL, API_USER_SUFFIX, API_USER_PROFILE_SUFFIX, BASE_URL } from "../constants/urls";

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
    if (!response.ok) {
        console.log(response)
        throw Error(response.statusText);
    }
    return response;
}

function getAPIURL(endpointSuffix) {
    return API_URL + endpointSuffix;
}

export const getActiveUserProfileBegin = () => ({ type: GET_ACTIVE_USER_PROFILE_BEGIN });
export const getActiveUserProfileSuccess = (result) => ({ type: GET_ACTIVE_USER_PROFILE_SUCCESS, payload: result });
export const getActiveUserProfileError = error => ({ type: GET_ACTIVE_USER_PROFILE_ERROR, payload: error });

export function getActiveUserProfile() {
    return dispatch => {
        dispatch(getActiveUserProfileBegin());
        return fetch(`${BASE_URL}/api/v3/` + (API_USER_SUFFIX) + '?include_csrf', {
            method: "GET",
            //mode: "no-cors",
            credentials: 'include'
        }).then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(getActiveUserProfileSuccess(json));
                return json;
            })
            .catch(error => {console.log(error); dispatch(getActiveUserProfileError(error.message)) });
    };
}

export const postUserProfileBegin = () => ({type: POST_USERPROFILE_BEGIN})
export const postUserProfileError = error => ({type: POST_USERPROFILE_ERROR, payload: error})
export const postUserProfileSuccess = (result) => ({type: POST_USERPROFILE_SUCCESS, payload: result})

export function postUserProfile(params) {
    return dispatch => {
        dispatch(postUserProfileBegin());
        // Fix annual distance value -> multiply by 1000 to send it in meters
        params.annual_distance = params.annual_distance * 1000
        return fetch(`${BASE_URL}/api/v3/` + `profile/${params.id}/`, {
            method: "PATCH",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': window.csrftoken,
            },
            body: JSON.stringify(params)
        }).then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(postUserProfileSuccess(json))
                return json;
            })
            .catch(error => {console.log(error); dispatch(postUserProfileError(error.message)) });
        }
}

export const changeUserProfileSetting = (key, value) => ({type: CHANGE_USERPROFILE_SETTING, key: key, value: value})