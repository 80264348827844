import { combineReducers } from 'redux'
import advisors from './advisor'
import user from './user'
import cars from './car'
import results from './result'
import trips from './trip'
import locations from './location'
import onboarding from './onboarding'

const rootReducer = combineReducers({  
    advisors,
    cars,
    locations,
    results,
    trips,
    user,
    onboarding
})

export default rootReducer;