import {
    GET_TRIPS_BEGIN,
    GET_TRIPS_ERROR,
    GET_TRIPS_SUCCESS,
    GET_SINGLE_TRIP_BEGIN,
    GET_SINGLE_TRIP_ERROR,
    GET_SINGLE_TRIP_SUCCESS,
    DELETE_TRIP_BEGIN,
    DELETE_TRIP_ERROR,
    DELETE_TRIP_SUCCESS,
} from "../constants/action-types-trip.js";
import moment from 'moment';
import { ADVISEE_SELECTED } from "../constants/action-types-advisor.js";

const initialState = {
    trips: {
        data: null,
        data_per_car: null,
        loading: false,
        error: null,
        data_per_car_ev_zero: null
    },
    selectedTrip:{
        data: null,
        loading: false,
        error: null
    }
};

const trips = (state = initialState, action) => {
    switch (action.type) {       
        case ADVISEE_SELECTED:
            return {...state, trips: {data: null, loading: false, error: null}}
        
        case GET_TRIPS_BEGIN:
            return {...state, trips: {data: null, loading: true, error: null}}

        case GET_TRIPS_ERROR:
            return {...state, trips: {data: null, loading: false, error: action.payload}}

        case GET_TRIPS_SUCCESS:
            let trips = action.payload;
            let data_per_car = {}
            let data_per_car_ev_zero = {}
            // Sort by date
            let data_sorted = action.payload.slice(0);
            data_sorted.sort((a, b) => moment(a.start_time).unix() - moment(b.start_time).unix() ) 
            data_sorted.map(trip => {
                trip.results.map(result => {
                    if (!data_per_car[result.car.id]) {
                        data_per_car[result.car.id] = []
                    }
                    // Reshape this object so that we can add a result with the trip info in result.drive_route
                    // Remove the result.drive_route.results since it would be redundant
                    let resultWithTrip = {
                        ...result,
                        drive_route: {
                            ...trip,
                            results: null
                        }
                    }
                    data_per_car[result.car.id].push(resultWithTrip)


                    // Trips where min_soc below zero
                    if (result.min_soc !== null && result.min_soc <= 0) {
                        if (!data_per_car_ev_zero[result.car.id]) {
                            data_per_car_ev_zero[result.car.id] = []
                        }

                        data_per_car_ev_zero[result.car.id].push(resultWithTrip)
                    }
                })
            })

            return {...state, trips: {data: action.payload, data_per_car: data_per_car, data_per_car_ev_zero: data_per_car_ev_zero, loading: false, error: null}}

        case GET_SINGLE_TRIP_BEGIN:
            return {...state, selectedTrip: {data: null, loading: true, error: null}}

        case GET_SINGLE_TRIP_ERROR:
            return {...state, selectedTrip: {data: null, loading: false, error: action.payload}}

        case GET_SINGLE_TRIP_SUCCESS:
            let positions_sorted = action.payload.positions.slice(0)
            positions_sorted.sort((a, b) => moment(a.timestamp).unix() - moment(b.timestamp).unix() ) 
        
            let coordinates = [];
            positions_sorted.map(position => {
                coordinates.push({
                    lat: position.latitude,
                    lng: position.longitude
                })
            })
            let data = {
                ...action.payload,
                coordinates: coordinates,
                positions_sorted: positions_sorted
            } 
            return {...state, selectedTrip: {data: data, loading: false, error: null}}

        default:
            return state;
    }
}

export default trips;