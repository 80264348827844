import React, { Component, Suspense } from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import Loadable from 'react-loadable';
import FullPageLoader from './components/_include/FullPageLoader';
import store from "./store/index";
import { Provider } from "react-redux";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import themeJSON from './theme.json';
import CssBaseline from '@material-ui/core/CssBaseline';

import { useTranslation, withTranslation, Trans } from 'react-i18next';

// Vendor specific inits
// react-dates datepicker init (e.g. EV section)
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

// Import Page definitions
import Pages from './components/Pages';

// Theme
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true; // Remove deprecation warning, use v4 typography styles
const theme = createMuiTheme(themeJSON);


class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <Router basename="/">
                    <Suspense fallback={<FullPageLoader />}>
                        <MuiThemeProvider theme={theme}>
                            <CssBaseline />
                            <Route component={Pages} />
                        </MuiThemeProvider>
                    </Suspense>
                </Router>
            </Provider>
        );
    }
}

export default App;