import {
    GET_RESULTS_BEGIN,
    GET_RESULTS_ERROR,
    GET_RESULTS_SUCCESS,

} from "../constants/action-types-result";

const initialState = {
    results: {
        data: null,
        data_per_car: null,
        loading: false,
        error: null
    }
};

const results = (state = initialState, action) => {
    switch (action.type) {
        case GET_RESULTS_BEGIN:
            return {...state, results: {...state.results, loading: true, error: null}}
        
        case GET_RESULTS_ERROR:
            return {...state, results: {...state.results, loading: false, error: action.payload, data: null, data_per_car: null}}

        case GET_RESULTS_SUCCESS:
            let data_per_car = {}
            action.payload.map(result => {
                if (!data_per_car[result.car.id]) {
                    data_per_car[result.car.id] = []
                }
                data_per_car[result.car.id].push(result)
            })
            return {...state, results: {...state.results, loading: false, error: null, data: action.payload, data_per_car: data_per_car}}
        
        
        default:
            return state;
    }
}

export default results;